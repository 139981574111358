import React from "react"
import styled from "styled-components";
import Button from "./button"
import theme from "../constants/theme";
import SECTIONS from "../constants/sections";

const Wrapper = styled.div`
  width: 100%;
  background: none;
  padding: .25rem;
  background: ${props => props.theme.body.transparentBackground};
`;

const Nav = styled.div`
  height: 2.5rem;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  @media(min-width: ${props => props.theme.body.contentWidth}) {
    width: ${props => props.theme.body.contentWidth};
  }
`;

const Spacer = styled.span`
  padding-left: .5rem;
`;

const InactiveNavButtonStyle = `
  background: none;
  border: solid 1px rgb(0, 0, 0, 0);
  color: white;

  &:hover,
  &:focus {
    border: solid 1px ${props => (props.color ? props.color : "white")};
  }
`;

const NavButton = styled(Button)`
  ${props => (
    props.inactive
      ? InactiveNavButtonStyle
      : ""
  )}
  
  &:hover,
  &:focus {
    color: ${props => (props.color ? props.color : "white")};
    text-shadow: 0 0 1px ${props => (props.color ? props.color : "white")};
    box-shadow: 0 0 5px ${props => (props.color ? props.color : "white")};
  }
`;

const Header = (props) => {

  return (
    <Wrapper>
      <Nav>
        <NavButton
          to={SECTIONS.about.url}
          color={theme.nav.about}
          fullHeight={true}
          inactive={props.active !== SECTIONS.about.label}
        >
          {SECTIONS.about.label}
        </NavButton>
        <Spacer/>
        <NavButton
          to={SECTIONS.experience.url}
          color={theme.nav.experience}
          fullHeight={true}
          inactive={props.active !== SECTIONS.experience.label}
        >
          {SECTIONS.experience.label}
        </NavButton>
        <Spacer />
        <NavButton
          to={SECTIONS.contact.url}
          color={theme.nav.contact}
          fullHeight={true}
          inactive={props.active !== SECTIONS.contact.label}
        >
          {SECTIONS.contact.label}
        </NavButton>
      </Nav>
    </Wrapper>
  )
}

export default Header;
