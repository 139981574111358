import React from "react"
import styled from "styled-components";
import PropTypes from "prop-types"
import Header from "./header"
import Root from "./root";
import SEO from "./seo";

const Wrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  height: 100vh;
  width: 100%

`;

const ContentWrapper = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  width: 100%;
  background: ${props => props.theme.body.transparentBackground};
`;

const Content = styled.div`
  margin: auto;
  height: 100%;
`;

const Section = (props) => (
  <Root>
    <SEO
      title={"Developer Dave " + props.seo}
      description="The official domain of Developer Dave, a skilled software engineer with years of experience in shipping quality code to production."
      author="Dave Mariano"
    />
    <Wrapper>
      <Header active={props.name}/>
      <ContentWrapper>
        <Content>
          {props.children}
        </Content>
      </ContentWrapper>
    </Wrapper>
  </Root>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Section
