import React from "react";
import styled from "styled-components";
import Section from "../components/section";
import github from "../images/github-min.png";
import linkedIn from "../images/linkedin-min.png";
import mail from "../images/mail-min.png";
import SECTIONS from "../constants/sections";
import theme from "../constants/theme";
import { Link } from "gatsby";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  overflow-y: auto;

  @media(min-width: ${props => props.theme.screens.medium}) {
    width: ${props => props.theme.screens.medium};
  }

`;

const ContactDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LogoButton = styled.div`
  text-align: center;
  color: ${props => props.color};
  cursor: pointer;
  padding: .5em;
  border: solid 1px rgb(0, 0, 0, 0);

  &:hover,
  &:focus {
    transition: .5s;
    text-shadow: 0 0 1px;
    box-shadow: 0 0 5px;
    border: solid 1px ${props => props.color};
  }
`;

const Logo = styled.img`
  height: 64px;
  display: block;
  margin: auto;
`;

const LogoText = styled.div`
  font-size: 1.5em;
  margin-top: .25em;
  color: ${props => props.color};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  
  &:hover, &:active, &:visited, &:focus {
    text-decoration:none;
  }
`;


const Contact = () => (
  <Section name={SECTIONS.contact.label} seo={SECTIONS.contact.seo}>
    <Wrapper>
      <Content>
        <ContactDiv>
          <StyledLink to="mailto:developer.dave@outlook.com" target="_blank">
            <LogoButton color={theme.nav.about}>
              <Logo alt="developer.dave@outlook.com" src={mail} />
            </LogoButton>
          </StyledLink>
          <LogoText color={theme.nav.about}>developer.dave@outlook.com</LogoText>
        </ContactDiv>

        <ContactDiv>
          <StyledLink to="https://www.linkedin.com/in/davemariano/" target="_blank">
            <LogoButton color={theme.nav.experience}>
              <Logo alt="@davemariano" src={linkedIn} />
            </LogoButton>
          </StyledLink>
          <LogoText color={theme.nav.experience}>@davemariano</LogoText>
        </ContactDiv>

        <ContactDiv>
          <StyledLink to="https://github.com/d-mariano" target="_blank">
            <LogoButton color={theme.nav.contact}>
              <Logo alt="@d-mariano" src={github} />
            </LogoButton>
          </StyledLink>
          <LogoText color={theme.nav.contact}>@d-mariano</LogoText>
        </ContactDiv>

      </Content>
    </Wrapper>
  </Section>
);

export default Contact;